import React, { useEffect }  from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import SEO from "../components/seo"
import FullWidthImage from "../components/FullWidthImage";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import ContentLayout from "../components/ContentLayout";


// eslint-disable-next-line
export const ProductPageTemplate = ({
  image,
  title,
  heading,
  description,
  subheading,  
  topContentText,
  topContentTagline,
  topContentImage,
  middleContentText,
  middleContentTagline,
  middleContentImage,
  bottomContentText,
  bottomContentTagline,
  bottomContentImage,
  bottomtitle
}) => {

  const heroImage = getImage(image) || image;
  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <div className="container" style={{width:'80%', paddingTop: 75, paddingBottom:75}}>
        <div className="column has-text-centered " >
          <h2 className="has-text-weight-semibold is-size-2">
            {heading}
          </h2>
          <h4>{description}</h4>
        </div>
      </div>
      <div className="column is-12 has-text-centered" >
        <Link className="btn" to="/contact" >
          Sign up
        </Link>
      </div>
      <div>   
        <ContentLayout Image={topContentImage} Tagline={topContentTagline} Text={topContentText} imagePosition="right" />   
      </div>
      <div>   
        <ContentLayout Image={middleContentImage} Tagline={middleContentTagline} Text={middleContentText}  />   
      </div>
      {/* <div>   
        <ContentLayout Image={bottomContentImage} Tagline={bottomContentTagline} Text={bottomContentText} imagePosition="right" />   
      </div> */}
      <div style={{background: 'linear-gradient(to top, #E2DFDF, #FFFFFF)'}}>
        <div class="container center" style={{paddingBottom: 150, paddingTop: 150, width:'70%'}}>
          <div className="columns  align-center">
            <div className="column has-text-centered" >
              <h1 className="test-giga text-center is-size-1">
                {bottomtitle}
              </h1>
            </div>
          </div>
          <div className="column is-12 has-text-centered" >
              <Link className="btn" to="/contact" >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>

  );
};

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  topContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  topContentText:  PropTypes.string,
  topContentTagline:  PropTypes.string,
  middleContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  middleContentText:  PropTypes.string,
  middleContentTagline:  PropTypes.string,
  bottomContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bottomContentText:  PropTypes.string,
  bottomContentTagline:  PropTypes.string,
  bottomtitle: PropTypes.string,
  meta :  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const ProductPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark;
  console.log(frontmatter.meta.title)

  const { metatitle } = frontmatter.meta.title;

  console.log("tile log " + frontmatter.meta.title)
  return (
    <Layout>
      <SEO title={frontmatter.meta.title} description={frontmatter.meta.description} />
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        topContentImage={frontmatter.topContentImage}
        topContentText={frontmatter.topContentText}
        topContentTagline={frontmatter.topContentTagline}
        middleContentImage={frontmatter.middleContentImage}
        middleContentText={frontmatter.middleContentText}
        middleContentTagline={frontmatter.middleContentTagline}
        bottomContentImage={frontmatter.bottomContentImage}
        bottomContentText={frontmatter.bottomContentText}
        bottomContentTagline={frontmatter.bottomContentTagline}
        bottomtitle={frontmatter.bottomtitle}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        description
        topContentText
        topContentTagline
        topContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        middleContentText
        middleContentTagline
        middleContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        bottomContentText
        bottomContentTagline
        bottomContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        bottomtitle
        meta {
          title
          description
        }
      }
    }
  }
`;
